import React from 'react';



const App = () => {

	return (
		<h4 style={{ textAlign: "center", marginTop: "50px"}}>Coming soon...</h4>
	);
}

export default App;
